.masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
}
.masonry-grid-column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
}

h3 {
    padding-bottom: 10px;
}